import React, { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "../Core/Icon/default";
import logoee from "../../resources/assets/EE_full.png";
import logoFescol from "../../resources/assets/logo-fescol-azul_2x.png";
import logoFiedrich from "../../resources/assets/logo_azul_fondo_transparente_2x.png";
import logoCorona from "../../resources/assets/logo_corona.png";
import logoCorona2x from "../../resources/assets/logo_corona_2x.png";

const Credits = ({ title, data }) => {
  let navigate = useNavigate();
  const { state } = useLocation();

  const handleClick = () => {
    const config = {};
    config.pathname = state?.origin || "/";
    const index = state?.result?.index || null;
    if (index) {
      config.search = `?index=${index}`;
    }
    navigate(config);
  };
  return (
    <div className="Credits">
      <Icon
        className="Icon Icon_prev"
        onClick={() => handleClick()}
        icon="chevron-left"
      />
      <div className="Credits-Title">{title}</div>
      <div className="Credits-Brands">
        <div className="Credits-Row Credits-RowUp">
          <img
            alt="Logo Fiedrich"
            className="Credits-Image"
            src={logoFiedrich}
          />
          <img alt="Logo Fescol" className="Credits-Image" src={logoFescol} />
          <img
            alt="Logo Fundacion Corona"
            className="Credits-Image Credits_desktop"
            src={logoCorona2x}
          />
        </div>
        <div className="Credits-Row Credits-RowDown">
          <img
            alt="Logo Fundacion Corona"
            className="Credits-Image Credits_mobile Credits-Image_corona"
            src={logoCorona}
          />
          <img
            alt="Logo El Espectador"
            className="Credits-Image Credits-Image_ee"
            src={logoee}
          />
        </div>
      </div>
      <div className="Credits-Content">
        {data?.map((el, key) => {
          return (
            <Fragment key={`Credit-Item_${key}`}>
              <div className="Credits-Key">{el.cargo}</div>
              <div className="Credits-Value">
                {Array.isArray(el.persona)
                  ? el.persona.map((el) => <div>{el}</div>)
                  : el.persona}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Credits;
