import React, { useState } from 'react';
import Button from '../Core/Button/default'
import { useNavigate } from "react-router-dom";


const PopUp = (props) => {
    let navigate = useNavigate();
    const [active, setActive] = useState(true);
    const storageName = "survey_history";
    const handleClick = () => {
        window.hasPopUpClickeds = true;
        setActive(false)
        if (props.callback) {
            props.callback();
        }
    }
    const handleClickBack = () => {
        window.localStorage.removeItem(storageName);
        navigate("/");
    }
    const hasLocalStorageData =
        window
            .localStorage
            .getItem(storageName);
    return active && hasLocalStorageData ? <div id="Survey-PopUp"><div class="Survey-PopUp Survey-PopUp_1"></div>
        <div class="Container Container-Example">
            <div class="Survey Survey_popUp Survey_degrade">
                <div class="Survey-PopUpIcon">
                    <svg id="Componente_60_1" data-name="Componente 60 – 1" xmlns="http://www.w3.org/2000/svg" width="82.616" height="82.616" viewBox="0 0 82.616 82.616">
                        <g id="Grupo_30732" data-name="Grupo 30732">
                            <circle id="Elipse_1179" data-name="Elipse 1179" cx="41.308" cy="41.308" r="41.308" fill="rgba(108,76,229,0.07)" />
                        </g>
                        <g id="Grupo_30743" data-name="Grupo 30743" transform="translate(35.406 14.852)">
                            <g id="Grupo_30742" data-name="Grupo 30742" transform="translate(0 17.887)">
                                <path id="Trazado_16692" data-name="Trazado 16692" d="M634.811,331.426v23.818a6.69,6.69,0,0,1-6.781,6.437c-3.674-.156-6.781-2.828-6.781-6.437V331.426a6.691,6.691,0,0,1,6.781-6.438c3.674.156,6.781,2.829,6.781,6.438Z" transform="translate(-621.25 -324.983)" fill="#6c4ce5" stroke="#6c4ce5" strokeWidth="1" />
                            </g>
                            <circle id="Elipse_1181" data-name="Elipse 1181" cx="6.781" cy="6.781" r="6.781" transform="translate(0.085 0)" fill="#6c4ce5" stroke="#6c4ce5" strokeWidth="1" />
                        </g>
                    </svg>
                </div>
                <div class="Survey-PopUpText"><strong>{props.questionHeader}</strong></div>
                <div class="Survey-PopUpText Survey-PopUpText_alternative">{props.question}</div>
                <div class="Survey-PopUpText Survey-PopUpText_alternative">{props.questionDescription}</div>
                <Button className={"Button_blue"} text="Continuar" onClick={handleClick} />
                <div class="Survey-PopUpText Survey-PopUpText_back" onClick={handleClickBack}>Volver al inicio</div>
            </div>
        </div>
    </div> : null;

}

export default PopUp;
