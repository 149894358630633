import React from 'react';
import { Icon } from '../Core/Icon/default';
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";


const SocialMediaShare = ({ className, shareUrl, quote, darkMode, fbIcon, twitterIcon, emailIcon }) => {
    return <div className={`SocialMedia ${className || ''}`}>
        <FacebookShareButton
            url={`${shareUrl}?utm_source=interno&utm_medium=boton&utm_campaig=match_regional&utm_content=boton_facebook_share_notas`}
            quote={quote}
        >
            <Icon icon={fbIcon} className={`SocialMedia-Icon`} />
        </FacebookShareButton>
        <TwitterShareButton
            url={`${shareUrl}?utm_source=interno&utm_medium=boton&utm_campaig=match_regional&utm_content=boton_twitter_share_notas`}
            quote={quote}
        >
            <Icon icon={twitterIcon} className={`SocialMedia-Icon`} />
        </TwitterShareButton>
        <EmailShareButton
            url={`${shareUrl}?utm_source=interno&utm_medium=boton&utm_campaig=match_regional&utm_content=boton_email_share_notas`}
            quote={quote}
        >
            <Icon icon={emailIcon} className={`SocialMedia-Icon`} />
        </EmailShareButton>
    </div>
};


export default SocialMediaShare;

