import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Survey,
  StylesManager,
  Model,
  Serializer,
  surveyLocalization,
} from "survey-react";
import SocialMediaShare from "../SocialMediaShare/default";
import { Icon } from "../Core/Icon/default";
import match from "../../resources/json/match.json";
import finalQuestion from "../../resources/json/finalQuestion.json";
import Question1 from "../../resources/assets/examples/Example-text.png";
// import { Icon, buildIconSvg } from '../Core/Icon/default';
// import Question1 from "../../resources/assets/examples/QuestionType1.png";
// import Question1_360 from "../../resources/assets/examples/QuestionType1360.png";
// import Question2 from "../../resources/assets/examples/QuestionType2.png";
// import Question3 from "../../resources/assets/examples/QuestionType3.png";
import PopUp from "../PopUp/default";
const axios = require("axios");

Serializer.findProperty("question", "minWidth").defaultValue = "0";
//add a property to the base question class and as result to all questions
Serializer.addProperty("question", {
  name: "choices_categories",
  default: "",
  category: "general",
});

Serializer.addProperty("question", {
  name: "category",
  default: "",
  category: "general",
});
//Serializer.findProperty("question", "hideNumber").defaultValue = true;
StylesManager.applyTheme("modern");

//Example of adding new locale into the library.
var mycustomSurveyStrings = {
  progressText: "{0}/{1}",
  pagePrevText: "<",
  pageNextText: "Siguiente pregunta",
  completeText: "Finalizar",
};
surveyLocalization.locales["my"] = mycustomSurveyStrings;
var myCss = {
  footer: "Survey-Footer",
  navigation: {
    complete: "Survey-Button Survey-Button_complete",
    prev: "Survey-Button Survey-Button_prev",
    next: "Survey-Button Survey-Button_next",
    start: "Survey-Button Survey-Button_start",
    preview: "Survey-Button Survey-Button_preview",
    edit: "Survey-Button Survey-Button_edit",
  },
  progress: "sv-progress sv-body__progress Survey-Progress",
  progressBar: "sv-progress__bar Survey-ProgressBar",
  progressTextUnderBar: "Survey-ProgressBarText",
};

const handlePageChange = (survey) => {
  var prev = document.getElementById("surveyPrev");

  if (prev) prev.style.display = !survey.isFirstPage ? "inline" : "none";
};

const ratingQuestion = (
  name,
  question = "",
  category = "",
  categories = [],
  minRateText = "Totalmente en desacuerdo",
  maxRateText = "Totalmente de acuerdo",
  rateCount = 6,
  rateValues = [1, 2, 3, 4, 5, 6],
  required = true
) => {
  let _result = {
    type: "rating",
    category: category,
    name: name,
    title: question,
    isRequired: required,
    minRateDescription: minRateText,
    maxRateDescription: maxRateText,
    choices_categories: categories,
    requiredErrorText: "Campo Requerido",
    rateCount: rateCount,
    rateValues: rateValues,
    hideNumber: true,
  };
  return _result;
};

const information = [
  {
    elements: [
      {
        type: "html",
        name: "Question1Explanation",
        html: `<div id="Survey-PopUp"><div  class="Survey-PopUp Survey-PopUp_1"></div>
                    <div class="Container Container-Example">
                    <div class="Survey Survey_degrade">
                    <div class="Survey-PopUpIcon">
                    <svg id="Componente_60_1" data-name="Componente 60 – 1" xmlns="http://www.w3.org/2000/svg" width="82.616" height="82.616" viewBox="0 0 82.616 82.616">
  <g id="Grupo_30732" data-name="Grupo 30732">
    <circle id="Elipse_1179" data-name="Elipse 1179" cx="41.308" cy="41.308" r="41.308" fill="rgba(108,76,229,0.07)"/>
  </g>
  <g id="Grupo_30743" data-name="Grupo 30743" transform="translate(35.406 14.852)">
    <g id="Grupo_30742" data-name="Grupo 30742" transform="translate(0 17.887)">
      <path id="Trazado_16692" data-name="Trazado 16692" d="M634.811,331.426v23.818a6.69,6.69,0,0,1-6.781,6.437c-3.674-.156-6.781-2.828-6.781-6.437V331.426a6.691,6.691,0,0,1,6.781-6.438c3.674.156,6.781,2.829,6.781,6.438Z" transform="translate(-621.25 -324.983)" fill="#6c4ce5" stroke="#6c4ce5" strokeWidth="1"/>
    </g>
    <circle id="Elipse_1181" data-name="Elipse 1181" cx="6.781" cy="6.781" r="6.781" transform="translate(0.085 0)" fill="#6c4ce5" stroke="#6c4ce5" strokeWidth="1"/>
  </g>
  </svg>
  
                    </div>
                    <div class="Survey-PopUpText Survey-PopUpText_example">Selecciona la opción de acuerdo a tu posición con las siguientes preguntas. <strong>Ejemplo</strong></div>
                    <picture>     
                        <source
                            srcSet="${Question1}"
                            media="(max-width: 600px)"
                        />               
                        <source
                            srcSet="${Question1}"
                            media="(max-width: 1200px)"
                            />
                        <img class="Survey-PopUpImage" src="${Question1}"/>
                    </picture>
                    <div class="Survey-PopUpText Survey-PopUpText_smallFont">* Contesta todas las preguntas para encontrar con precisión a tu candidato/a ideal.</div>
                    <button class="Survey-Button Survey-Button_next Survey-Button_example" id="SurveyButtonExample">Empezar</button> 
                    </div> 
                    </div>`,
      },
    ],
  },
];
const _ids = [
  {
    condition: "{proyecto_1} + {proyecto_2} + {proyecto_3} + {proyecto_4}",
    validation: "{proyecto_4}",
  },
  {
    condition: "{proyecto_5} + {proyecto_6} + {proyecto_7} + {proyecto_8}",
    validation: "{proyecto_8}",
  },
  {
    condition: "{proyecto_9} + {proyecto_10} + {proyecto_11} + {proyecto_12}",
    validation: "{proyecto_12}",
  },
  {
    condition: "{proyecto_13} + {proyecto_14} + {proyecto_15} + {proyecto_16}",
    validation: "{proyecto_16}",
  },
  {
    condition: "{proyecto_17} + {proyecto_18} + {proyecto_19} + {proyecto_20}",
    validation: "{proyecto_20}",
  },
];
const questionsArray = match.content_elements[0].questions.map(
  (element, key) => {
    if (element.answers) {
      return {
        elements: [
          ratingQuestion(
            element._id,
            element.label,
            "",
            [],
            element.description_1,
            element.description_2
          ),
        ],
      };
    } else {
      const subElement = element.questions.map((subElement) => {
        const _ids = [];
        _ids.push(subElement._id);
        return ratingQuestion(
          subElement._id,
          subElement.label,
          "",
          [],
          subElement.description_1,
          subElement.description_2,
          7,
          [10, 20, 30, 40, 50, 60, 70]
        );
      });
      const question = {
        type: "html",
        name: element._id,
        html: `<div class="Survey-QuestionTitle Survey-Question" >${element.label}</div> `,
      };
      const validation = {
        type: "expression",
        name: "Advertencia",
        visibleIf: `${_ids[key - 2].condition}!=100`,
        hideNumber: true,
        title: `* Recuerda: la suma de dinero que asignas debe dar exactamente 100 pesos`,
      };
      subElement.unshift(question);
      subElement.push(validation);
      return { elements: subElement };
    }
  }
);

const finalQuestionData = finalQuestion.content_elements[0].questions.map(
  (element) => {
    if (element.answers) {
      return {
        elements: [
          ratingQuestion(
            element._id,
            element.label,
            "",
            [],
            element.description_1,
            element.description_2
          ),
        ],
      };
    }
  }
);

const finalQuestions = [...information, ...questionsArray];
const surveyJson = (isFinalQuestion) => {
  return {
    questionStartIndex: " ",
    requiredText: "",
    completedHtml: `<div id="Survey-PopUp"><div  class="Survey-PopUp"></div>
    <div class="Container Container_homepage Container_loading ">
    <div class="Homepage Homepage_loading">
    <div class="Homepage-Content">
    <div class="Homepage-Loading">
    <svg id="Componente_71_1" data-name="Componente 71 – 1" xmlns="http://www.w3.org/2000/svg" width="46.667" height="46.667" viewBox="0 0 46.667 46.667">
    <g id="Grupo_26661" data-name="Grupo 26661">
    <path id="Trazado_13729" data-name="Trazado 13729" d="M507.628,616.832A1.628,1.628,0,0,1,506,615.2V603.628a1.628,1.628,0,0,1,3.256,0V615.2A1.628,1.628,0,0,1,507.628,616.832Z" transform="translate(-484.294 -602)" fill="#fff"/>
    <path id="Trazado_13730" data-name="Trazado 13730" d="M507.628,675.5A1.628,1.628,0,0,1,506,673.871V662.295a1.628,1.628,0,0,1,3.256,0v11.576A1.628,1.628,0,0,1,507.628,675.5Z" transform="translate(-484.294 -628.832)" fill="#fff" opacity="0.5"/>
    <path id="Trazado_13731" data-name="Trazado 13731" d="M479.2,645.256H467.628a1.628,1.628,0,1,1,0-3.256H479.2a1.628,1.628,0,0,1,0,3.256Z" transform="translate(-466 -620.294)" fill="#fff"/>
    <path id="Trazado_13732" data-name="Trazado 13732" d="M537.871,645.256H526.295a1.628,1.628,0,0,1,0-3.256h11.576a1.628,1.628,0,1,1,0,3.256Z" transform="translate(-492.832 -620.294)" fill="#fff" opacity="0.7"/>
    <path id="Trazado_13733" data-name="Trazado 13733" d="M487.53,625.157a1.622,1.622,0,0,1-1.151-.477l-8.186-8.186a1.628,1.628,0,1,1,2.3-2.3l8.186,8.186a1.628,1.628,0,0,1-1.151,2.779Z" transform="translate(-471.358 -607.358)" fill="#fff"/>
    <path id="Trazado_13734" data-name="Trazado 13734" d="M529.013,666.64a1.623,1.623,0,0,1-1.151-.477l-8.186-8.186a1.628,1.628,0,0,1,2.3-2.3l8.186,8.186a1.628,1.628,0,0,1-1.151,2.779Z" transform="translate(-490.331 -626.331)" fill="#fff" opacity="0.6"/>
    <path id="Trazado_13735" data-name="Trazado 13735" d="M479.344,666.64a1.628,1.628,0,0,1-1.151-2.779l8.186-8.186a1.628,1.628,0,0,1,2.3,2.3l-8.186,8.186A1.623,1.623,0,0,1,479.344,666.64Z" transform="translate(-471.358 -626.331)" fill="#fff"/>
    <path id="Trazado_13736" data-name="Trazado 13736" d="M520.827,625.157a1.628,1.628,0,0,1-1.151-2.779l8.186-8.186a1.628,1.628,0,0,1,2.3,2.3l-8.186,8.186A1.622,1.622,0,0,1,520.827,625.157Z" transform="translate(-490.331 -607.358)" fill="#fff" opacity="0.8"/>
    </g>
    </svg>
    </div>
    <div class="Homepage-LoadingText">
    cargando resultados...
    </div>
    </div>
    </div>
    </div>`,
    showProgressBar: "top",
    pages: isFinalQuestion ? finalQuestionData : finalQuestions,
  };
};

const DefaultSurvey = (props) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { result } = state || {};
  const hasActivePopUp = result?.popUp === 1;
  const isFinalQuestion = props.finalQuestion || false;
  const survey = new Model(surveyJson(isFinalQuestion));
  survey.locale = "my";
  const handlePrev = (survey) => {
    survey.prevPage();
  };
  const checkSumOfValues = () => {
    const currentPage = survey.currentPage;
    if (currentPage.visibleIndex >= 3 && currentPage.visibleIndex <= 8) {
      const questions = currentPage.questions;
      let sum = 0;
      const value = [];
      questions.forEach((question) => {
        if (question.value) {
          sum += question.value;
          value.push(question.value);
        }
      });

      // Obtener el botón "Next" por su clase CSS (ajusta esto según tu código HTML)
      const nextButton =
        document.getElementsByClassName("Survey-Button_next")[0] ||
        document.getElementsByClassName("Survey-Button_complete")[0];
      // Mostrar u ocultar el botón según la suma
      if (sum !== 100 || value.length < 4) {
        nextButton.disabled = true;
      } else {
        nextButton.disabled = false;
      }
    } else {
      // Obtener el botón "Next" por su clase CSS (ajusta esto según tu código HTML)
      const nextButton =
        document.getElementsByClassName("Survey-Button_next")[0] ||
        document.getElementsByClassName("Survey-Button_complete")[0];
      nextButton.disabled = false;
    }
  };
  const pageValidation = () => {
    const currentPage = survey.currentPage;
    const questions = currentPage.questions;
    let questionValidation = false;
    questions.forEach((question) => {
      questionValidation = question.isAnswered;
    });
    const nextButton = document.getElementsByClassName("Survey-Button_next")[0];
    if (questionValidation) {
      nextButton.disabled = false;
    } else {
      nextButton.disabled = true;
    }
  };
  survey.onCurrentPageChanged.add(pageValidation);
  survey.onValueChanged.add(checkSumOfValues);

  const _city = {
    ciudad: props.site,
  };

  const storageName = "survey_history";
  const storageOldDataName = isFinalQuestion
    ? "survey_history_old_final"
    : "survey_history_old";
  const storageResultName = "survey_result_match";

  var finalData = JSON.parse(window.localStorage.getItem("survey_history_old"));
  finalData = { ...finalData?._city, ...finalData?.data };

  function saveSurveyData(survey, isComplete = false) {
    var data = survey.data;
    isFinalQuestion && (survey = finalData);
    finalData.nivel_decision_voto_posterior = data.nivel_decision_voto_posterior;

    if (isComplete) {
      window.localStorage.setItem(
        storageOldDataName,
        JSON.stringify(isFinalQuestion ? finalData : { _city, data })
      );
      window.localStorage.removeItem(
        storageName,
        JSON.stringify(isFinalQuestion ? finalData : data)
      );
    } else {
      window.localStorage.setItem(
        storageName,
        JSON.stringify(isFinalQuestion ? finalData : data)
      );
    }
  }

  const alertResults = useCallback(async (sender) => {
    saveSurveyData(sender, true);
    let results = sender.data;
    results = isFinalQuestion ? finalData : { ..._city, ...results };
    var config = {
      method: "post",
      url: isFinalQuestion
        ? process.env.REACT_APP_FESCOL_API_RESULT_DOMAIN
        : process.env.REACT_APP_FESCOL_API_DOMAIN,
      headers: {
        accept: "application/json",
        "X-API-Key": process.env.REACT_APP_FESCOL_API_KEY,
        "Content-Type": "application/json",
      },
      data: results,
    };
    const result = await axios(config);
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        storageResultName,
        JSON.stringify(result?.data || {})
      );
    }
    navigate(isFinalQuestion ? "/resultados-finales" : "/resultados-ejemplo", { state: { result: result.data } });
  }, []);
  survey.onComplete.add(alertResults);
  survey.onPartialSend.add(function (sender) {
    saveSurveyData(sender);
  });

  survey.sendResultOnPageNext = true;
  var prevData = window.localStorage.getItem(storageName) || null;
  if (prevData) {
    var data = JSON.parse(prevData);
    survey.data = data;
  }
  survey.onAfterRenderQuestion.add(function (survey, options) {
    //Hide pop up when is present
    const dataLayer = window.dataLayer || null;
    if (dataLayer && !finalQuestion) {
      dataLayer.push({
        event: "page_question",
        eventLabel: `Preguntas_pagina_${survey.currentPageNo + 1}`,
      });
    }
    window.scrollTo(0, 0);
    var popUpButton = document.getElementById("SurveyButtonExample");
    if (popUpButton) {
      popUpButton.onclick = function () {
        var popUp = document.getElementById("Survey-PopUp");
        popUp.style.display = "none";
        survey.nextPage();
      };
    }
  });
  survey.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options?.cssClasses;
    var category = options?.question?.propertyHash?.category || "";
    //var categories = options?.question?.propertyHash?.choices_categories || [];
    classes.mainRoot = "Survey-Question";
    classes.root = "Survey-QuestionRoot";
    classes.header = "Survey-QuestionHeader";
    classes.content = "Survey-QuestionContent";
    classes.footer = "Survey-QuestionFooter";
    classes.item = "Survey-QuestionItem";
    classes.itemText = "Survey-QuestionItemText";
    classes.selected = "Survey-QuestionItem_selected";
    classes.number = "Survey-QuestionNumber";
    classes.title = "Survey-QuestionTitle";
    classes.minText = "Survey-QuestionMinText";
    classes.maxText = "Survey-QuestionMaxText";
    classes.titleOnAnswer = "Survey-QuestionTitle_onAnswer";
    classes.titleOnError = "Survey-QuestionTitle_error";
    if (classes.error) {
      classes.error.root = "Survey-ErrorBox";
      classes.error.locationTop = "Survey-ErrorBox_top";
      classes.error.locationBottom = "Survey-ErrorBox_bottom";
    }
    if (category === "options" && options?.question?.getType() === "rating") {
      classes.header = "Survey-QuestionHeader_none";
      classes.itemText = "Survey-QuestionItemText_none";
    }
    if (options?.question?.getType() === "html") {
      classes.mainRoot = "Survey-Question_html";
      classes.content = "Survey-QuestionContent_html";
    }
    if (options?.question?.getType() === "radiogroup") {
      classes.mainRoot += " Survey-Question_radiogroup";
      classes.title += " Survey-QuestionTitle_radiogroup";
      classes.item += " Survey-QuestionItem_radiogroup";
      classes.content += " Survey-QuestionContent_radiogroup";
      //classes.itemControl = "Survey-QuestionControl"
      classes.label += " Survey-QuestionLabel_radiogroup";
      classes.controlLabel = "Survey-QuestionControlLabel";
      classes.materialDecorator = "Survey-QuestionControlDecorator";
      classes.itemChecked += " Survey-QuestionItemChecked";
      //classes.itemDecorator = "Survey-QuestionControlDecorator"
    }
    if (options?.question?.getType() === "expression") {
      classes.mainRoot = "Survey-QuestionWarning";
    }
  });
  survey.onUpdatePageCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;
    classes.row = "Survey-PageRow";
    classes.pageTitle = "Survey-PageTitle";
    classes.pageDescription = "Survey-PageDescription";
    if (classes.page) {
      classes.page.root = "Survey-Page";
    }
  });
  handlePageChange(survey);
  return (
    <div>
      {!hasActivePopUp && (
        <PopUp
          questionHeader="¿ALGO FALLÓ?"
          question="Calma, guardamos tus respuestas."
          questionDescription={"Retoma el cuestionario acá."}
          callback={() => {
            navigate(`/matching-${props.site}`, {
              state: { result: { popUp: 1 } },
            });
          }}
        />
      )}
      {!finalQuestion && (
        <SocialMediaShare
          className="SocialMedia_fixed"
          shareUrl={process.env.REACT_APP_DOMAIN}
          fbIcon="facebook"
          twitterIcon="twitter"
          emailIcon="envelope"
        />
      )}
      {!finalQuestion && (
        <Icon
          className="Icon Icon_prev"
          onClick={() => handlePrev(survey)}
          icon="chevron-left"
        />
      )}
      <Survey
        model={survey}
        className={`Survey`}
        css={myCss}
        onCurrentPageChanged={() => handlePageChange(survey)}
      />
    </div>
  );
};

export default DefaultSurvey;
