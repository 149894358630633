import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import '../styles/index.scss';
import { Icon } from '../Icon/default';
//import 'swiper/components/thumbs/thumbs.min.css';
/** Add Swiper components */
import SwiperCore, {
    Navigation,
    Pagination,
    Thumbs,
    Autoplay,
} from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SurveyEE from '../../SurveyEE/default';

SwiperCore.use([Navigation, Pagination, Thumbs, Autoplay]);

export const Carousel = (props) => {
    const [prevRef, setPrevRef] = useState(React.useRef(null));
    const [nextRef, setNextRef] = useState(React.useRef(null));
    const _elements =
        props.children && Array.isArray(props.children) ? props.children : [];
    const arrowIcons = props.arrowIcons
        ? props.arrowIcons
        : ['chevron-left', 'chevron-left'];
    const gallery = props.gallery
        ? 'Carousel-Arrows Carousel-Arrows_Gallery'
        : 'Carousel-Arrows';
    const dragBox = props.DragBox ? ' Carousel-Arrows_DragBox' : '';
    const _autoplaySeconds = props.autoplaySeconds * 1000;
    return (
        <Swiper
            observer={props.setObservers ? props.setObservers : false}
            observeParents={props.setObservers ? props.setObservers : false}
            className={['Carousel', props.className].join(' ')}
            spaceBetween={props.spaceBetween}
            initialSlide={props.initialSlide || 0}
            loop={props.loop}
            thumbs={props.thumbs}
            autoplay={
                props.autoplay && {
                    delay: _autoplaySeconds,
                    disableOnInteraction: false,
                }
            }
            navigation={{
                nextEl: nextRef,
                prevEl: prevRef,
            }}
            pagination={{
                clickable: true,
                type: "fraction",
                renderBullet: function (index, className) {
                    return `<span
                    class="${['Carousel-Dot', className].join(' ')}"
                  ></span>`;
                },
            }}
            autoHeight={true}
            onSlideChange={(e) => props.onChange(e)}
            onSwiper={props.onSwiper}
            breakpoints={
                props.breakpoints
                    ? props.breakpoints
                    : {
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                            centeredSlides: true,
                        },
                        // when window width is >= 640px
                        1200: {
                            slidesPerView: 4,
                            centeredSlides: false,
                        },
                    }
            }
        >
            {/* Add Arrows */}
            <div className={gallery + dragBox}>
                <div
                    className='Carousel-Arrow'
                    ref={(e) => {
                        setPrevRef(e);
                    }}
                >
                    <Icon
                        icon={arrowIcons[0]}
                        className='Icon Icon_carousel Icon_carousel_prev '
                        alt='Flecha izquierda'
                    />
                </div>
                <div
                    className='Carousel-Arrow'
                    ref={(e) => {
                        setNextRef(e);
                    }}
                >
                    <Icon
                        icon={arrowIcons[1]}
                        className='Icon Icon_carousel Icon_carousel_next'
                        alt='Flecha derecha'
                    />
                </div>
            </div>

            {_elements.map((element, key) => (
                <SwiperSlide key={`SwiperSlide${key}`} className='Carousel-Slide'>
                    {element}
                </SwiperSlide>
            ))}
            <SwiperSlide key={7}>
                <SurveyEE finalQuestion={true} infoLabel={props.infoLabel}  creditsLabel={props.creditsLabel}/>
            </SwiperSlide>
        </Swiper>
    );
};

Carousel.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    slides: PropTypes.number,
    spaceBetween: PropTypes.number,
    loop: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySeconds: PropTypes.number,
    arrowIcons: PropTypes.array,
    gallery: PropTypes.bool,
    DragBox: PropTypes.bool,
};

Carousel.defaultProps = {
    onClick: undefined,
    className: '',
    title: '',
    slides: 1,
    spaceBetween: 10,
    loop: false,
    autoplay: false,
    gallery: false,
    DragBox: false,
};
