import React from "react";
import { Icon } from "../Core/Icon/default";

const Graphic = ({ className, img, x, y, x1, y1, text, textCandidate }) => {
  const textGraphic = text && <div className="Graphic-Text">{text}</div>;
  const _textCandidate = textCandidate ? (
    <div className="Graphic-Text">{textCandidate.split(" ")[0]}</div>
  ):null;
  const candidatePoint = x1 && y1 ? (
    <div style={{ top: `${y1}%`, left: `${x1}%` }} className="Graphic-Point">
      {_textCandidate}
      <div className="Graphic-Point_dotCandidate"></div>
    </div>
  ):null;
  return (
    <div className={`Graphic ${className || ""}`}>
      <div className="Graphic-Frame">
        <img
          alt="Gráfica de resultados"
          className="Graphic-Image"
          src={img || ""}
        />
        <div className="Graphic-Cuadrant">
          <div className={`Graphic-Reference ${text ? "Graphic-TextContent":""}`} id="Graphic">
            <div
              style={{ top: `${y}%`, left: `${x}%` }}
              className="Graphic-Point"
            >
              {textGraphic}
              <Icon className="Graphic-Point_dot" icon={"user-index-icon"} />
            </div>
            {candidatePoint}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graphic;
