import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Core/Button/default";
import { Icon } from "../Core/Icon/default";
import logoee from "../../resources/assets/logo_ee_2x.png";
import logoFescol from "../../resources/assets/logo-fescol-azul_2x.png";
import logoFiedrich from "../../resources/assets/logo_azul_fondo_transparente_2x.png";
import logoCorona from "../../resources/assets/logo_corona_2x.png";
import SocialMediaShare from "../SocialMediaShare/default";
import PopUp from "../PopUp/default";

const HomePage = (props) => {
  const [navigateCanonical,setNavigateCanonical] = useState(window?.localStorage.getItem("canonical_url") || "/");
  let navigate = useNavigate();
  return (
    <div className={`Homepage ${props.isUpdating ? "Homepage_updating" : ""}`}>
      {!props.isUpdating && (
        <PopUp
          questionHeader="¿ALGO FALLÓ?"
          question="Calma, guardamos tus respuestas."
          questionDescription={"Retoma el cuestionario acá."}
          callback={() => {
            navigate(navigateCanonical, { state: { result: { popUp: 1 } } });
          }}
        />
      )}
      {!props.isUpdating && (
        <SocialMediaShare
          className="SocialMedia_dark"
          shareUrl={process.env.REACT_APP_DOMAIN}
          fbIcon="facebook"
          twitterIcon="twitter"
          emailIcon="envelope"
        />
      )}
      {!props.isUpdating && (
        <div className="Homepage-Content">
          <div className="Homepage-Title" />
          <div className="Homepage-Description">{props.description || ""}</div>
          <div className="Button-Container">
            <div>
              <Button
                className={"Button_blue Button_homepage"}
                text="Bogotá"
                onClick={() => {
                  setNavigateCanonical(window?.localStorage.setItem("canonical_url","/matching-bogota"));
                  navigate("/matching-bogota");
                }}
              />
              <Button
                className={"Button_blue Button_homepage"}
                text="Medellín"
                onClick={() => {
                  setNavigateCanonical(window?.localStorage.setItem("canonical_url","/matching-medellin"));
                  navigate("/matching-medellin");
                }}
              />
            </div>
            <div>
              <Button
                className={"Button_blue Button_homepage"}
                text="Cali"
                onClick={() => {
                  setNavigateCanonical(window?.localStorage.setItem("canonical_url","/matching-cali"));
                  navigate("/matching-cali");
                }}
              />
              <Button
                className={"Button_blue Button_homepage"}
                text="Barranquilla"
                onClick={() => {
                  setNavigateCanonical(window?.localStorage.setItem("canonical_url","/matching-barranquilla"));
                  navigate("/matching-barranquilla");
                }}
              />
            </div>
          </div>
          <div>
            <div className="Homepage-Link">
              <div
                onClick={() =>
                  navigate("/politica-de-datos", { state: { origin: "/" } })
                }
              >
                {props.linkText || ""}
              </div>
            </div>
            <div
              className="Homepage-Info Title-Info"
              onClick={() => navigate("/info", { state: { origin: "/" } })}
            >
              <Icon className="Icon" icon="informacion-icono" />
              {props.info || ""}
            </div>
            <div className="Homepage-Brands">
              <img
                alt="Logo Fiedrich"
                className="Homepage-BrandImage"
                src={logoFiedrich}
              />
              <img
                alt="Logo Fescol"
                className="Homepage-BrandImage"
                src={logoFescol}
              />
              <img
                alt="Logo El Espectador"
                className="Homepage-BrandImage"
                src={logoee}
              />
              <img
                alt="Logo Fundacion Corona"
                className="Homepage-BrandImage"
                src={logoCorona}
              />
            </div>
          </div>
        </div>
      )}
      {props.isUpdating && (
        <div className="Homepage-Content">
          <div className="Homepage-Title" />
          <div className="Homepage-Loading">
            <Icon icon="update-icon" />
          </div>
          <div class="Homepage-LoadingText">EN ACTUALIZACIÓN</div>
          <div className="Homepage-Link Title-Link">
            <a href="https://www.elespectador.com/">Volver al home</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
