import albertYordano from "../../resources/assets/candidates/albert-yordano.png";
import albertYordano360 from "../../resources/assets/candidates/albert-yordano360.png";
import albertYordano600 from "../../resources/assets/candidates/albert-yordano600.png";
import alejandroChar from "../../resources/assets/candidates/alejandro-char.png";
import alejandroChar360 from "../../resources/assets/candidates/alejandro-char360.png";
import alejandroChar600 from "../../resources/assets/candidates/alejandro-char600.png";
import alejandroEder from "../../resources/assets/candidates/alejandro-eder.png";
import alejandroEder360 from "../../resources/assets/candidates/alejandro-eder360.png";
import alejandroEder600 from "../../resources/assets/candidates/alejandro-eder600.png";
import antonioEduardo from "../../resources/assets/candidates/antonio-eduardo.png";
import antonioEduardo360 from "../../resources/assets/candidates/antonio-eduardo360.png";
import antonioEduardo600 from "../../resources/assets/candidates/antonio-eduardo600.png";
import antonioRenteria from "../../resources/assets/candidates/antonio-renteria.png";
import antonioRenteria360 from "../../resources/assets/candidates/antonio-renteria360.png";
import antonioRenteria600 from "../../resources/assets/candidates/antonio-renteria600.png";
import carlosAlberto from "../../resources/assets/candidates/carlos-alberto.png";
import carlosAlberto360 from "../../resources/assets/candidates/carlos-alberto360.png";
import carlosAlberto600 from "../../resources/assets/candidates/carlos-alberto600.png";
import carlosGalan from "../../resources/assets/candidates/carlos-galan.png";
import carlosGalan360 from "../../resources/assets/candidates/carlos-galan360.png";
import carlosGalan600 from "../../resources/assets/candidates/carlos-galan600.png";
import deicyElena from "../../resources/assets/candidates/deicy-elena.png";
import deicyElena360 from "../../resources/assets/candidates/deicy-elena360.png";
import deicyElena600 from "../../resources/assets/candidates/deicy-elena600.png";
import deninsonMendoza from "../../resources/assets/candidates/deninson-mendoza.png";
import deninsonMendoza360 from "../../resources/assets/candidates/deninson-mendoza360.png";
import deninsonMendoza600 from "../../resources/assets/candidates/deninson-mendoza600.png";
import diegoMolano from "../../resources/assets/candidates/diego-molano.png";
import diegoMolano360 from "../../resources/assets/candidates/diego-molano360.png";
import diegoMolano600 from "../../resources/assets/candidates/diego-molano600.png";
import edilsonHuerfano from "../../resources/assets/candidates/edilson-huerfano.png";
import edilsonHuerfano360 from "../../resources/assets/candidates/edilson-huerfano360.png";
import edilsonHuerfano600 from "../../resources/assets/candidates/edilson-huerfano600.png";
import federicoGutierrez from "../../resources/assets/candidates/federico-gutierrez.png";
import federicoGutierrez360 from "../../resources/assets/candidates/federico-gutierrez360.png";
import federicoGutierrez600 from "../../resources/assets/candidates/federico-gutierrez600.png";
import felipeVelez from "../../resources/assets/candidates/felipe-velez.png";
import felipeVelez360 from "../../resources/assets/candidates/felipe-velez360.png";
import felipeVelez600 from "../../resources/assets/candidates/felipe-velez600.png";
import gilbertoTobon from "../../resources/assets/candidates/gilberto-tobon.png";
import gilbertoTobon360 from "../../resources/assets/candidates/gilberto-tobon360.png";
import gilbertoTobon600 from "../../resources/assets/candidates/gilberto-tobon600.png";
import gustavoBolivar from "../../resources/assets/candidates/gustavo-bolivar.png";
import gustavoBolivar360 from "../../resources/assets/candidates/gustavo-bolivar360.png";
import gustavoBolivar600 from "../../resources/assets/candidates/gustavo-bolivar600.png";
import harryAlberto from "../../resources/assets/candidates/harry-alberto.png";
import harryAlberto360 from "../../resources/assets/candidates/harry-alberto360.png";
import harryAlberto600 from "../../resources/assets/candidates/harry-alberto600.png";
import hassanFares from "../../resources/assets/candidates/hassan-fares.png";
import hassanFares360 from "../../resources/assets/candidates/hassan-fares360.png";
import hassanFares600 from "../../resources/assets/candidates/hassan-fares600.png";
import heribertoEscobar from "../../resources/assets/candidates/heriberto-escobar.png";
import heribertoEscobar360 from "../../resources/assets/candidates/heriberto-escobar360.png";
import heribertoEscobar600 from "../../resources/assets/candidates/heriberto-escobar600.png";
import jaimeAlberto from "../../resources/assets/candidates/jaime-alberto.png";
import jaimeAlberto360 from "../../resources/assets/candidates/jaime-alberto360.png";
import jaimeAlberto600 from "../../resources/assets/candidates/jaime-alberto600.png";
import jorgeRobledo from "../../resources/assets/candidates/jorge-robledo.png";
import jorgeRobledo360 from "../../resources/assets/candidates/jorge-robledo360.png";
import jorgeRobledo600 from "../../resources/assets/candidates/jorge-robledo600.png";
import jorgeVargas from "../../resources/assets/candidates/jorge-vargas.png";
import jorgeVargas360 from "../../resources/assets/candidates/jorge-vargas360.png";
import jorgeVargas600 from "../../resources/assets/candidates/jorge-vargas600.png";
import juanCamilo from "../../resources/assets/candidates/juan-camilo.png";
import juanCamilo360 from "../../resources/assets/candidates/juan-camilo360.png";
import juanCamilo600 from "../../resources/assets/candidates/juan-camilo600.png";
import juanCarlos from "../../resources/assets/candidates/juan-carlos.png";
import juanCarlos360 from "../../resources/assets/candidates/juan-carlos360.png";
import juanCarlos600 from "../../resources/assets/candidates/juan-carlos600.png";
import juanDavid from "../../resources/assets/candidates/juan-david.png";
import juanDavid360 from "../../resources/assets/candidates/juan-david360.png";
import juanDavid600 from "../../resources/assets/candidates/juan-david600.png";
import juanOviedo from "../../resources/assets/candidates/juan-oviedo.png";
import juanOviedo360 from "../../resources/assets/candidates/juan-oviedo360.png";
import juanOviedo600 from "../../resources/assets/candidates/juan-oviedo600.png";
import lilianaMaria from "../../resources/assets/candidates/liliana-maria.png";
import lilianaMaria360 from "../../resources/assets/candidates/liliana-maria360.png";
import lilianaMaria600 from "../../resources/assets/candidates/liliana-maria600.png";
import luisBernardo from "../../resources/assets/candidates/luis-bernardo.png";
import luisBernardo360 from "../../resources/assets/candidates/luis-bernardo360.png";
import luisBernardo600 from "../../resources/assets/candidates/luis-bernardo600.png";
import luisEnrique from "../../resources/assets/candidates/luis-enrique.png";
import luisEnrique360 from "../../resources/assets/candidates/luis-enrique360.png";
import luisEnrique600 from "../../resources/assets/candidates/luis-enrique600.png";
import mariaPaulina from "../../resources/assets/candidates/maria-paulina.png";
import mariaPaulina360 from "../../resources/assets/candidates/maria-paulina360.png";
import mariaPaulina600 from "../../resources/assets/candidates/maria-paulina600.png";
import miyerlandiTorres from "../../resources/assets/candidates/miyerlandi-torres.png";
import miyerlandiTorres360 from "../../resources/assets/candidates/miyerlandi-torres360.png";
import miyerlandiTorres600 from "../../resources/assets/candidates/miyerlandi-torres600.png";
import nicolasRamos from "../../resources/assets/candidates/nicolas-ramos.png";
import nicolasRamos360 from "../../resources/assets/candidates/nicolas-ramos360.png";
import nicolasRamos600 from "../../resources/assets/candidates/nicolas-ramos600.png";
import rafaelQuintero from "../../resources/assets/candidates/rafael-quintero.png";
import rafaelQuintero360 from "../../resources/assets/candidates/rafael-quintero360.png";
import rafaelQuintero600 from "../../resources/assets/candidates/rafael-quintero600.png";
import robertoOrtiz from "../../resources/assets/candidates/roberto-ortiz.png";
import robertoOrtiz360 from "../../resources/assets/candidates/roberto-ortiz360.png";
import robertoOrtiz600 from "../../resources/assets/candidates/roberto-ortiz600.png";
import rodolfoAndres from "../../resources/assets/candidates/rodolfo-andres.png";
import rodolfoAndres360 from "../../resources/assets/candidates/rodolfo-andres360.png";
import rodolfoAndres600 from "../../resources/assets/candidates/rodolfo-andres600.png";
import rodrigoLara from "../../resources/assets/candidates/rodrigo-lara.png";
import rodrigoLara360 from "../../resources/assets/candidates/rodrigo-lara360.png";
import rodrigoLara600 from "../../resources/assets/candidates/rodrigo-lara600.png";
import ronaldJose from "../../resources/assets/candidates/ronald-jose.png";
import ronaldJose360 from "../../resources/assets/candidates/ronald-jose360.png";
import ronaldJose600 from "../../resources/assets/candidates/ronald-jose600.png";
import wilsonRuiz from "../../resources/assets/candidates/wilson-ruiz.png";
import wilsonRuiz360 from "../../resources/assets/candidates/wilson-ruiz360.png";
import wilsonRuiz600 from "../../resources/assets/candidates/wilson-ruiz600.png";
import wilfredoPardo from "../../resources/assets/candidates/wilfredo-pardo.png";
import wilfredoPardo360 from "../../resources/assets/candidates/wilfredo-pardo360.png";
import wilfredoPardo600 from "../../resources/assets/candidates/wilfredo-pardo600.png";
import silueta from "../../resources/assets/candidates/Silueta.png";
import heatMapBogota from "../../resources/assets/city_HeatMap/heatMap-Bogota.svg";
import heatMapBarranquilla from "../../resources/assets/city_HeatMap/heatMap-Barranquilla.svg";
import heatMapMedellin from "../../resources/assets/city_HeatMap/heatMap-Medellin.svg";
import heatMapCali from "../../resources/assets/city_HeatMap/heatMap-Cali.svg";
export const candidatesInfo =
{
    "Cali": {
        heatmap: heatMapCali,
        "Haz click aquí y escoge un candidato": {
            name: "Candidato",
            "1x1": silueta,
            360: silueta,
            600: silueta,
            //link: "https://www.elespectador.com/politica/elecciones-colombia-2022/de-frente-con-enrique-gomez-propuestas-elecciones-2022/",
            age: "Edad",
            party: "Partido",
            value: null
            //heatmap: enriqueGomezHeatMap,
        },
        ///////////////////
        //CALI
        ///////////////////
        "Roberto Ortiz Urueña": {
            360: robertoOrtiz360,
            600: robertoOrtiz600,
            name: "Roberto Ortiz Urueña",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "62 años",
            party: "Firme Con Cali",
            "1x1": robertoOrtiz
        },
        "Alejandro Eder Garcés": {
            360: alejandroEder360,
            600: alejandroEder600,
            name: "Alejandro Eder Garcés",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "47 años",
            party: "Revivamos Cali",
            "1x1": alejandroEder
        },
        "Danis Rentería Chala": {
            360: antonioRenteria360,
            600: antonioRenteria600,
            name: "Danis Rentería Chala",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "52 años",
            party: "Colombia Humana",
            "1x1": antonioRenteria
        },
        "Deninson Mendoza Ramos": {
            360: deninsonMendoza360,
            600: deninsonMendoza600,
            name: "Deninson Mendoza Ramos",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "43 años",
            party: "Independientes",
            "1x1": deninsonMendoza
        },
        "Édilson Huérfano Ordóñez": {
            360: edilsonHuerfano360,
            600: edilsonHuerfano600,
            name: "Édilson Huérfano Ordóñez",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "48 años",
            party: "Fuerza ciudadana",
            "1x1": edilsonHuerfano
        },
        "Heriberto Escobar González": {
            360: heribertoEscobar360,
            600: heribertoEscobar600,
            name: "Heriberto Escobar González",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "45 años",
            party: "Gente en movimiento",
            "1x1": heribertoEscobar
        },
        "Miyerlandi Agredo Torres": {
            360: miyerlandiTorres360,
            600: miyerlandiTorres600,
            name: "Miyerlandi Agredo Torres",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "48 años",
            party: "Un renacer para Cali",
            "1x1": miyerlandiTorres
        },
        "Wilson Ruiz Orejuela": {
            360: wilsonRuiz360,
            600: wilsonRuiz600,
            name: "Wilson Ruiz Orejuela",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "56 años",
            party: "S.O.S Cali - Salvemos a Cali",
            "1x1": wilsonRuiz
        },
        "Wilfredo Pardo Herrera": {
            360: wilfredoPardo360,
            600: wilfredoPardo600,
            name: "Wilfredo Pardo Herrera",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "62 años",
            party: "Liga hobernantes Anticorrupción",
            "1x1": wilfredoPardo
        },
    },
    "Bogotá D.C.": {
        heatmap: heatMapBogota,
        "Haz click aquí y escoge un candidato": {
            name: "Candidato",
            "1x1": silueta,
            360: silueta,
            600: silueta,
            //link: "https://www.elespectador.com/politica/elecciones-colombia-2022/de-frente-con-enrique-gomez-propuestas-elecciones-2022/",
            age: "Edad",
            party: "Partido",
            value: null
            //heatmap: enriqueGomezHeatMap,
        },
        ///////////////////
        //BOGOTÁ
        ///////////////////
        "Carlos Fernando Galán": {
            360: carlosGalan360,
            600: carlosGalan600,
            name: "Carlos Fernando Galán",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "46 años",
            party: "Partido Nuevo Liberalismo",
            "1x1": carlosGalan
        },
        "Gustavo Bolívar": {
            360: gustavoBolivar360,
            600: gustavoBolivar600,
            name: "Gustavo Bolívar",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "58 años",
            party: "Pacto Histórico Bogotá",
            "1x1": gustavoBolivar,
        },
        "Rafael Alfonso Quintero": {
            360: rafaelQuintero360,
            600: rafaelQuintero600,
            name: "Rafael Alfonso Quintero",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "72 años",
            party: "Movimiento Alianza Democrática Amplia",
            "1x1": rafaelQuintero
        },
        "Juan Daniel Oviedo": {
            360: juanOviedo360,
            600: juanOviedo600,
            name: "Juan Daniel Oviedo",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "46 años",
            party: "Con toda por Bogotá",
            "1x1": juanOviedo,
        },
        "Jorge Enrique Robledo": {
            360: jorgeRobledo360,
            600: jorgeRobledo600,
            name: "Jorge Enrique Robledo",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "73 años",
            party: "Dignidad & Compromiso",
            "1x1": jorgeRobledo
        },
        "Jorge Luis Vargas Valencia": {
            360: jorgeVargas360,
            600: jorgeVargas600,
            name: "Jorge Luis Vargas Valencia",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "56 años",
            party: "Cambio Radical",
            "1x1": jorgeVargas
        },
        "Diego Molano": {
            360: diegoMolano360,
            600: diegoMolano600,
            name: "Diego Molano",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "53 años",
            party: "Reconstruyamos Bogotá",
            "1x1": diegoMolano
        },
        "Nicolás Ramos Barbosa": {
            360: nicolasRamos360,
            600: nicolasRamos600,
            name: "Nicolás Ramos Barbosa",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "33 años",
            party: "Más Acciones Menos Rostros",
            "1x1": nicolasRamos
        },
        "Rodrigo Lara": {
            360: rodrigoLara360,
            600: rodrigoLara600,
            name: "Rodrigo Lara",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "48 años",
            party: "Lara-Demócrata",
            "1x1": rodrigoLara
        },
    },
    "Barranquilla": {
        heatmap: heatMapBarranquilla,
        "Haz click aquí y escoge un candidato": {
            name: "Candidato",
            "1x1": silueta,
            360: silueta,
            600: silueta,
            //link: "https://www.elespectador.com/politica/elecciones-colombia-2022/de-frente-con-enrique-gomez-propuestas-elecciones-2022/",
            age: "Edad",
            party: "Partido",
            value: null
            //heatmap: enriqueGomezHeatMap,
        },
        ///////////////////
        //Barranquilla
        ///////////////////
        "Ronald José Valdés Padilla": {
            360: ronaldJose360,
            600: ronaldJose600,
            name: "Ronald José Valdés Padilla",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "47 años",
            party: "Movimiento Alianza Democrática Amplia",
            "1x1": ronaldJose
        },
        "Luis Enrique Guzmán Chams": {
            360: luisEnrique360,
            600: luisEnrique600,
            name: "Luis Enrique Guzmán Chams",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "44 años",
            party: "Verde Oxígeno",
            "1x1": luisEnrique
        },
        "Hassan Fares": {
            360: hassanFares360,
            600: hassanFares600,
            name: "Hassan Fares",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "53 años",
            party: "Partido Ecologista Colombiano",
            "1x1": hassanFares
        },
        "Harry Silva": {
            360: harryAlberto360,
            600: harryAlberto600,
            name: "Harry Silva",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "52 años",
            party: "S.O.S Por Barranquilla",
            "1x1": harryAlberto
        },
        "Antonio Bohorquez": {
            360: antonioEduardo360,
            600: antonioEduardo600,
            name: "Antonio Bohorquez",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "56 años",
            party: "Polo Democrático Alternativo",
            "1x1": antonioEduardo
        },
        "Alejandro Char": {
            360: alejandroChar360,
            600: alejandroChar600,
            name: "Alejandro Char",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "57 años",
            party: "Cambio Radical",
            "1x1": alejandroChar
        },
    },
    "Medellín": {
        heatmap: heatMapMedellin,
        "Haz click aquí y escoge un candidato": {
            name: "Candidato",
            "1x1": silueta,
            360: silueta,
            600: silueta,
            //link: "https://www.elespectador.com/politica/elecciones-colombia-2022/de-frente-con-enrique-gomez-propuestas-elecciones-2022/",
            age: "Edad",
            party: "Partido",
            value: null
            //heatmap: enriqueGomezHeatMap,
        },
        ////////////////
        //Medellín
        ///////////////
        "Rodolfo Andrés Correa Vargas": {
            360: rodolfoAndres360,
            600: rodolfoAndres600,
            name: "Rodolfo Andrés Correa Vargas",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "45 años",
            party: "Colombia Renaciente",
            "1x1": rodolfoAndres
        },
        "Maria Paulina Aguinaga Lizcano": {
            360: mariaPaulina360,
            600: mariaPaulina600,
            name: "Maria Paulina Aguinaga Lizcano",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            party: "Por Medellín",
            "1x1": mariaPaulina
        },
        "Luis Bernardo Vélez Montoya": {
            360: luisBernardo360,
            600: luisBernardo600,
            name: "Luis Bernardo Vélez Montoya",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "63 años",
            party: "Movimiento Cuidemos Medellín",
            "1x1": luisBernardo
        },
        "Liliana María Rendón Roldán": {
            360: lilianaMaria360,
            600: lilianaMaria600,
            name: "Liliana María Rendón Roldán",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "53 años",
            party: "Centro Democratico",
            "1x1": lilianaMaria,
        },
        "Juan David Valderrama López": {
            360: juanDavid360,
            600: juanDavid600,
            name: "Juan David Valderrama López",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "47 años",
            party: "Proponemos Por Medellín",
            "1x1": juanDavid
        },
        "Juan Carlos Upegui Vanegas": {
            360: juanCarlos360,
            600: juanCarlos600,
            name: "Juan Carlos Upegui Vanegas",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "34 años",
            party: "Independientes",
            "1x1": juanCarlos
        },
        "Juan Camilo Restrepo Gómez": {
            360: juanCamilo360,
            600: juanCamilo600,
            name: "Juan Camilo Restrepo Gómez",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "43 años",
            party: "Movimiento Salvación Nacional",
            "1x1": juanCamilo
        },
        "Jaime Alberto Mejía Alvarán": {
            360: jaimeAlberto360,
            600: jaimeAlberto600,
            name: "Jaime Alberto Mejía Alvarán",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "42 años",
            party: "Sumamos",
            "1x1": jaimeAlberto
        },
        "Gilberto Tobón Sanin": {
            360: gilbertoTobon360,
            600: gilbertoTobon600,
            name: "Gilberto Tobón Sanin",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "73 años",
            party: "Movimiento Politico Fuerza Ciudadana",
            "1x1": gilbertoTobon
        },
        "Felipe Vélez Roa": {
            360: felipeVelez360,
            600: felipeVelez600,
            name: "Felipe Vélez Roa",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "46 años",
            party: "Movimiento Alianza Democrática Amplia",
            "1x1": felipeVelez
        },
        "Federico Andrés Gutiérrez Zuluaga": {
            360: federicoGutierrez360,
            600: federicoGutierrez600,
            name: "Federico Andrés Gutiérrez Zuluaga",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "48 años",
            party: "Creemos",
            "1x1": federicoGutierrez
        },
        "Deicy Elena Bermúdez Hurtado": {
            360: deicyElena360,
            600: deicyElena600,
            name: "Deicy Elena Bermúdez Hurtado",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "40 años",
            party: "Partido Ecologista Colombiano",
            "1x1": deicyElena
        },
        "Carlos Alberto Ballesteros Bar": {
            360: carlosAlberto360,
            600: carlosAlberto600,
            name: "Carlos Alberto Ballesteros Bar",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "66 años",
            party: "Polo Democrático Alternativo",
            "1x1": carlosAlberto
        },
        "Albert Yordano Corredor Bustamante": {
            360: albertYordano360,
            600: albertYordano600,
            name: "Albert Yordano Corredor Bustamante",
            link: "https://www.elespectador.com/politica/elecciones-2023",
            age: "34 años",
            party: "Medellín Nos Une",
            "1x1": albertYordano
        },
    }
}

export const politicaDatos = {
    content: ["La presente herramienta no es una encuesta; solo busca apoyar a nuestras audiencias a tomar decisiones más informadas en esta contienda electoral y conocer de primera mano su opinión sobre hechos o circunstancias de todo el interés público. La data recolectada podrá ser utilizada de manera anónima para fines académicos por parte de las entidades participantes, tendrá una finalidad eminentemente estadística y su tratamiento se circunscribirá a nuestra política de tratamiento de datos, de acuerdo con la Ley 1581 de 2012."]
}

export const appInfo = {
    title: "Así hicimos este análisis",
    content: ["Primero, aplicamos una encuesta en las cuatro ciudades y a partir de las preferencias de los ciudadanos y ciudadanas frente a 20 proyectos relacionados con diferentes temáticas buscamos aproximarnos a sus posturas generales para ubicarles en un mapa de preferencias de proyectos. Con este mapa surgen clústers o grupos de cercanía y esto permite compararlos con otros grupos de electores. En este punto, la herramienta ofrece información sobre el máximo estimado de votantes activos en Bogotá, Cali, Medellín y Barranquilla, agrupados en los mismos perfiles frente a los proyectos. Este cálculo se realiza con base en una encuesta elaborada en septiembre de 2023 por la firma Cifras y Conceptos, a más de 1700 personas en las cuatro ciudades. En la encuesta se formularon las mismas preguntas que se usan en este Match electoral. Las mismas fueron diseñadas para el contexto de las cuatro ciudades durante las elecciones regionales de 2023. La adaptación del Match a temas locales se benefició de un ejercicio piloto que Cifras & Conceptos realizó con Propacífico."]
}

export const credits = [
    { cargo: "Productor general", persona: "Élber Gutiérrez Roa" },
    { cargo: "Asesor académico", persona: "Saruy Tolosa" },
    { cargo: "Coordinación general y dirección de encuesta nacional", persona: "César Caballero" },
    { cargo: "Encuesta nacional y base de datos de Cifras y Conceptos", persona: ["Miguel Ángel León", "Yennifer Pérez", "Yachay Tolosa","Felipe Ayala"] },
    { cargo: "Apoyó técnico Fundación Corona", persona: ["Daniel Uribe","Diana Dajer","Juan Carlos Fernández","Daniela Matiz"] },
    { cargo: "Editora web", persona: "Marcela Osorio" },
    { cargo: "Director de diseño", persona: "Mario Fernando Rodríguez" },
    { cargo: "Ingenieros de Desarrollo", persona: ["Maycol Hernández García", "Sergio Sebastián Ramirez Santamaria","Miguel Angel Lopez"] },
    { cargo: "Productor Digital", persona: ["Carlos Santafe A."] },
    { cargo: "Analista de calidad y pruebas", persona: ["Juan Camilo Fonseca"] }
]
