import React from 'react';
import { useNavigate } from "react-router-dom";
import { Icon } from "../Core/Icon/default";

const FinalResult = ({ className }) => {
    let navigate = useNavigate();
    const infoLabel = (
        <div
          onClick={() =>
            navigate("/info", {
              state: { origin: "/resultados-finales"},
            })
          }
          className={`Title-Info Title-Info_small`}
        >
          {" "}
          <Icon className="Icon" icon="informacion-icono" />
          ¿Cómo hicimos este análisis?
        </div>
      );
      const creditsLabel = (
        <div
          className="Title-Info Title-Info_small Title-Info_small_credits"
          onClick={() => {
            navigate("/creditos", {
              state: { origin: "/resultados-finales"},
            });
          }}
        >
          Créditos
        </div>
      );
    return (
        <div className={`FinalResult ${className || ''}`}>
            <div className="Survey Survey_final sv-root-modern"><form data-gtm-form-interact-id="0">
                <div className="sv_custom_header">
                </div>
                <div className="sv-container-modern">
                <div class="sv-progress sv-body__progress Survey-Progress"><div class="sv-progress__bar Survey-ProgressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100"><span class="sv-progress__text sv-hidden">1/1</span></div><span class="sv-progress__text Survey-ProgressBarText">1/1</span></div>
                    <div className="sv-body sv-body--responsive">
                        <div className="Survey-Page">
                            <div className="Survey-PageFinal">
                                <div>
                                    <div className="Survey-Question" aria-required="true" aria-invalid="false" aria-labelledby="sq_139_ariaTitle" data-rendered="r" data-name="nivel_decision_voto_posterior">
                                        <div className="Survey-QuestionHeader sv-question__header--location--top">
                                            <h5 className="Survey-QuestionTitle" aria-label="Después de realizar el match local y pensando en las elecciones del próximo 29 de octubre, ¿mantienes tu postura frente a la definición del voto a la Alcaldía de tu ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENES DECIDIDO y 6 es LO TIENES TOTALMENTE DECIDIDO">
                                                <span className="sv-string-viewer">Después de realizar el match local y pensando en las elecciones del próximo 29 de octubre, ¿mantienes tu postura frente a la definición del voto a la Alcaldía de tu ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENES DECIDIDO y 6 es LO TIENES TOTALMENTE DECIDIDO
                                                </span>
                                            </h5>
                                            <div className="sv-description sv-question__description">
                                                <span className="sv-string-viewer">
                                                </span>
                                            </div>
                                        </div>
                                        <div className="Survey-QuestionContent" role="presentation">
                                            <div className="Survey-QuestionRoot" data-rendered="r">
                                                <fieldset role="radiogroup">
                                                    <legend role="presentation" className="sv-hidden">
                                                    </legend>
                                                    <span className="Survey-QuestionMinText">
                                                        <span className="sv-string-viewer">No lo tiene decidido</span>
                                                    </span>
                                                    <label className="Survey-QuestionItem">
                                                        <input type="radio" className="sv-visuallyhidden" name="nivel_decision_voto_posterior" aria-required="true" aria-label="Luego de estas preguntas y pensando en las elecciones del próximo 29 de octubre, ¿usted mantiene su postura frente a su definición del voto a la Alcaldía de su ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENE DECIDIDO y 6 es LO TIENE TOTALMENTE DECIDIDO" aria-invalid="false" value="1" />
                                                        <span className="Survey-QuestionItemText">
                                                            <span className="sv-string-viewer">1
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="Survey-QuestionItem">
                                                        <input type="radio" className="sv-visuallyhidden" name="nivel_decision_voto_posterior" aria-required="true" aria-label="Luego de estas preguntas y pensando en las elecciones del próximo 29 de octubre, ¿usted mantiene su postura frente a su definición del voto a la Alcaldía de su ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENE DECIDIDO y 6 es LO TIENE TOTALMENTE DECIDIDO" aria-invalid="false" value="2" />
                                                        <span className="Survey-QuestionItemText">
                                                            <span className="sv-string-viewer">2
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="Survey-QuestionItem">
                                                        <input type="radio" className="sv-visuallyhidden" name="nivel_decision_voto_posterior" aria-required="true" aria-label="Luego de estas preguntas y pensando en las elecciones del próximo 29 de octubre, ¿usted mantiene su postura frente a su definición del voto a la Alcaldía de su ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENE DECIDIDO y 6 es LO TIENE TOTALMENTE DECIDIDO" aria-invalid="false" value="3" />
                                                        <span className="Survey-QuestionItemText">
                                                            <span className="sv-string-viewer">3
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="Survey-QuestionItem">
                                                        <input type="radio" className="sv-visuallyhidden" name="nivel_decision_voto_posterior" aria-required="true" aria-label="Luego de estas preguntas y pensando en las elecciones del próximo 29 de octubre, ¿usted mantiene su postura frente a su definición del voto a la Alcaldía de su ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENE DECIDIDO y 6 es LO TIENE TOTALMENTE DECIDIDO" aria-invalid="false" value="4" />
                                                        <span className="Survey-QuestionItemText">
                                                            <span className="sv-string-viewer">4
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="Survey-QuestionItem">
                                                        <input type="radio" className="sv-visuallyhidden" name="nivel_decision_voto_posterior" aria-required="true" aria-label="Luego de estas preguntas y pensando en las elecciones del próximo 29 de octubre, ¿usted mantiene su postura frente a su definición del voto a la Alcaldía de su ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENE DECIDIDO y 6 es LO TIENE TOTALMENTE DECIDIDO" aria-invalid="false" value="5" data-gtm-form-interact-field-id="0" />
                                                        <span className="Survey-QuestionItemText">
                                                            <span className="sv-string-viewer">5
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="Survey-QuestionItem">
                                                        <input type="radio" className="sv-visuallyhidden" name="nivel_decision_voto_posterior" aria-required="true" aria-label="Luego de estas preguntas y pensando en las elecciones del próximo 29 de octubre, ¿usted mantiene su postura frente a su definición del voto a la Alcaldía de su ciudad? En la misma escala de 1 a 6, donde 1 es NO LO TIENE DECIDIDO y 6 es LO TIENE TOTALMENTE DECIDIDO" aria-invalid="false" value="6" />
                                                        <span className="Survey-QuestionItemText">
                                                            <span className="sv-string-viewer">6
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <span className="Survey-QuestionMaxText">
                                                        <span className="sv-string-viewer">Lo tiene totalmente decidido
                                                        </span>
                                                    </span>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>
                <div className="Title-Info Title-Info_small Title-Link">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.elespectador.com/politica/elecciones-2023"
                    >
                        Vea toda la información elecciones 2023 en El Espectador
                    </a>
                </div>
            {infoLabel}
            {creditsLabel}
        </div>
    )
};

export default FinalResult;