//import logo from './logo.svg';
import React from 'react';
import './App.css';
import './resources/styles/index.scss'
import Container from './components/Container/default';
import Layout from './components/Layout/default';
import Home from './components/Home/default';
import SurveyEE from './components/SurveyEE/default';
import FinalResult from './components/FinalResult/default';
import Results, { ResultsSample } from './components/Results/default';
import InfoPage from './components/Info/default';
import Credits from './components/Credits/default';
import { appInfo, politicaDatos, credits} from './components/utilities/_conf';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import PopUp from './components/PopUp/default';
function App() {
  return (
    <Layout className={'Layout_tendency'}>
      {/**While updating */}
      {/*<div className='Layout-Background'></div>*/}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Container className={"Container_homepage"}><Home
            description={'Selecciona tu ciudad y contesta las preguntas que te ayudarán a encontrar cuál es el candidato más afín.'}
            link={'/politica-de-datos'}
            linkText={'Conoce nuestra política de datos'}
            linkInfo={'/info'}
            info={'¿Cómo funciona esta herramienta?'} /></Container>}
          />
          <Route path="/matching-bogota" element={<Container><SurveyEE site='Bogotá D.C.' /></Container>} />
          <Route path="/matching-medellin" element={<Container><SurveyEE site='Medellín' /></Container>} />
          <Route path="/matching-cali" element={<Container><SurveyEE site='Cali' /></Container>} />
          <Route path="/matching-barranquilla" element={<Container><SurveyEE site='Barranquilla' /></Container>} />
          <Route path="/resultados-ejemplo" element={<Container><ResultsSample /></Container>} />
          <Route path="/resultados" element={<Container><Results /></Container>} />
          <Route path="/resultados-finales" element={<Container><FinalResult /></Container>} />
          <Route path="/info" element={<div className='Layout_cookies'><div className='Survey-PopUp Survey-PopUp_2 Survey_degrade'></div><Container className={"Container-Info"}><InfoPage data={appInfo} /></Container></div>} />
          <Route path="/politica-de-datos" element={<div className='Layout_cookies'><div className='Survey-PopUp Survey-PopUp_2 Survey_degrade'></div><Container className={"Container-Info"}><InfoPage className="Info_smaller" data={politicaDatos}/></Container></div>} />
          <Route path="/creditos" element={
            <div>
              <div className='Survey-PopUp Survey-PopUp_3 Survey_degrade'></div>
              <Container className={"Container-Info"}><Credits title={"Créditos"} data={credits} /></Container>
            </div>} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
